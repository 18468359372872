import React, { useEffect, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Row, Col, Card } from 'antd';
import Lightbox from 'lightbox-react';
import Prismic from 'prismic-javascript';
import PrismicDOM from 'prismic-dom';

import Layout from '../components/layout';
import SEO from '../components/seo';

import 'lightbox-react/style.css';

const prismicURL = 'https://blackway.prismic.io/api/v2';

const Customs = () => {
	const [ fullscreenGallery, setFullscreenGallery ] = useState(false);
	const [ fullscreenIndex, setFullscreenIndex ] = useState(null);

	const [ team, setTeam ] = useState([]);

	const toggleFullscreenGallery = (index = null) => {
		setFullscreenGallery(!fullscreenGallery);
		setFullscreenIndex(index);
	};

	useEffect(() => {
		Prismic.getApi(prismicURL).then((api) => api.query(
			Prismic.Predicates.at('document.type', 'team')
		))
			.then(response => {
				setTeam(response.results[0].data.body);
			});
	}, []);

	return (
		<StaticQuery
			query={graphql`
		      query {
		        as01: file(relativePath: { eq: "atelier-showroom-01.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1400) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        as02: file(relativePath: { eq: "atelier-showroom-02.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1400) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        as03: file(relativePath: { eq: "atelier-showroom-03.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1400) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        as04: file(relativePath: { eq: "atelier-showroom-04.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1400) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        as05: file(relativePath: { eq: "atelier-showroom-05.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1400) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        as06: file(relativePath: { eq: "atelier-showroom-06.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1400) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }

		        as01tmb: file(relativePath: { eq: "atelier-showroom-01.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 320) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        as02tmb: file(relativePath: { eq: "atelier-showroom-02.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 320) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        as03tmb: file(relativePath: { eq: "atelier-showroom-03.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 320) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        as04tmb: file(relativePath: { eq: "atelier-showroom-04.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 320) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        as05tmb: file(relativePath: { eq: "atelier-showroom-05.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 320) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        as06tmb: file(relativePath: { eq: "atelier-showroom-06.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 320) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		      }
		    `}
			render={data => {
				const pictures = [
					data.as01.childImageSharp.fluid.src,
					data.as02.childImageSharp.fluid.src,
					data.as03.childImageSharp.fluid.src,
					data.as04.childImageSharp.fluid.src,
					data.as05.childImageSharp.fluid.src,
					data.as06.childImageSharp.fluid.src
				];
				const thumbnails = [
					data.as01tmb.childImageSharp.fluid,
					data.as02tmb.childImageSharp.fluid,
					data.as03tmb.childImageSharp.fluid,
					data.as04tmb.childImageSharp.fluid,
					data.as05tmb.childImageSharp.fluid,
					data.as06tmb.childImageSharp.fluid
				];

				return (
					<Layout>
						<SEO
							title="Garage"
							description="Venez découvrir notre garage et notre showroom de motos customs à Bernex !"
							lang="fr"
							url="/garage"
						/>

						<div className="box-container first">
							<div className="content-block">
								<div className="content-block-bg" />
								<h2>LE GARAGE</h2>

								<Row type="flex" justify="center" align="middle" gutter={16}>
									{thumbnails.map((tmb, key) => (
										<Col key={key} xs={12} md={8} xl={6} xxl={4} >
											<Card size="small" onClick={() => toggleFullscreenGallery(key)} hoverable>
												<Img fluid={tmb} style={{ maxWidth: '100%' }} alt="Atelier Black Way Motorcycles - Harley Davidson" />
											</Card>
										</Col>
									))}
								</Row>

								<Row type="flex" justify="center">
									<Col xs={24} md={20} xxl={16}>
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
											<h3>ATELIER ET ENTRETIENS</h3>
											<p>Grâce à notre expérience et notre atelier remis à neuf début 2017, nous vous proposons de nombreux services tels que l'entretien, réparation, diagnostiques mécanique ou électrique </p>
											<p>De plus, notre large gamme de machines et d'outils, nous permet de vous proposer la modification de votre moto ou la fabrication sur-mesure de celle de vos rêves.</p>
										</Card>
									</Col>
								</Row>

								<h2 style={{ marginTop: 120 }}>NOTRE ÉQUIPE</h2>

								<Row type="flex" justify="space-around" gutter={16}>
									{team.map((member, index) => (
										<Col key={index} xs={24} md={12} xl={8} xxl={6}>
											<Card
												hoverable
												cover={<img src={member.primary.image.url} rel="nofollow" />}
											>
												<Card.Meta title={member.primary.name[0].text} description={
													<div className="schedules" dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(member.primary.description) }} />
												} />
											</Card>
										</Col>
									))}
								</Row>
							</div>
						</div>

						{
							fullscreenGallery && (
								<Lightbox
									mainSrc={pictures[fullscreenIndex]}
									nextSrc={pictures[(fullscreenIndex + 1) % pictures.length]}
									prevSrc={pictures[(fullscreenIndex + pictures.length - 1) % pictures.length]}
									onCloseRequest={toggleFullscreenGallery}
									onMovePrevRequest={() => setFullscreenIndex((fullscreenIndex + pictures.length - 1) % pictures.length)}
									onMoveNextRequest={() => setFullscreenIndex((fullscreenIndex + 1) % pictures.length)}
								/>
							)
						}
					</Layout>
				)
			}}
		/>
	);
}

export default Customs;
